.content {
  height: 100% !important;
}
.fc-col-header,
.fc-timegrid-body {
  width: 100% !important;
  height: 100% !important;
}
.fc-scrollgrid-sync-table {
  width: 100% !important;
  height: 100% !important;
}
.fc-daygrid-body {
  width: 100% !important;
  height: 100% !important;
}
.fc-daygrid-body-balanced {
  width: 100% !important;
  height: 100% !important;
}
.fc-event-time {
  display: none;
}
